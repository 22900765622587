import React from "react";
import Seo from "components/Seo";
import MainTitle from "components/MainTitle";
import LayoutTerms, { Content } from "components/LayoutTerms";
import { Term, TermItem, TermTitle } from "components/Term";

export default function TermosDeUso(props) {
  return (
    <LayoutTerms {...props}>
      <Seo title="Termos de uso" description={`Termos de uso do Tecimob`} />
      <MainTitle
        titleTag="h1"
        title="Termos de uso"
      />
      <Content>
        <Term>
          <TermItem>
            <TermTitle>Introdução</TermTitle>
            <p>
              Estes são os nossos Termos de Uso. É necessário que você leia, compreenda e concorde com estes termos antes de utilizar o nosso serviço.
            </p>
          </TermItem>
        </Term>
        <Term>
          <TermItem>
            <TermTitle>Aceite dos Termos</TermTitle>
            <p>
              Este Contrato de Licença de Usuário Final (“Termos de Uso”) é um acordo legal entre o licenciado (pessoa física ou jurídica) (o “LICENCIADO”) e a CODEX SISTEMAS, pessoa jurídica de direito privado, inscrita no CNPJ sob nº 15.512.782/0001-15, com sede na Rua Simeão Esmeraldino de Menezes, 400, Uniparque (Módulo 38), CEP 88.704-090, bairro Dehon, Tubarão - Santa Catarina - Brasil (a “LICENCIANTE”).
            </p>
            <p>
              Estes Termos de Uso regem o uso do programa de computador denominado TECIMOB (o “SOFTWARE”), disponibilizado pela LICENCIANTE, pelo prazo determinado no ato do licenciamento. O uso do SOFTWARE, mesmo que parcial ou a título de teste, implica na aceitação integral dos termos deste contrato, especialmente no que se refere ao acesso, coleta, uso, armazenamento, tratamento e proteção das informações do LICENCIADO para a execução das funcionalidades do SOFTWARE. Em caso de discordância, o uso do SOFTWARE deve ser imediatamente interrompido.
            </p>
          </TermItem>
        </Term>

        <Term>
          <TermItem>
            <TermTitle>3. Público Alvo</TermTitle>
            <p>O SOFTWARE é destinado exclusivamente para corretores de imóveis, grupos de corretores de imóveis ou imobiliárias devidamente registrados no CRECI.</p>
          </TermItem>
        </Term>
        <Term>
          <TermItem>
            <TermTitle>4. Propriedade Intelectual</TermTitle>
            <p>O LICENCIADO não adquire, através deste contrato, quaisquer direitos de propriedade intelectual ou outros direitos exclusivos sobre o SOFTWARE ou suas partes, incluindo patentes, marcas, direitos autorais, informações confidenciais ou segredos de negócio. Todos os direitos não expressamente concedidos são reservados à LICENCIANTE. O conteúdo disponibilizado no site, como textos, gráficos, imagens, logos, ícones, fotografias, conteúdo editorial, notificações, softwares e outros materiais, é de propriedade exclusiva da LICENCIANTE ou devidamente licenciado.</p>
          </TermItem>
        </Term>
        <Term>
          <TermItem>
            <TermTitle>5. Declaração de Vontade</TermTitle>
            <p>O LICENCIADO declara ter conhecimento e concordar com todos os direitos e obrigações estabelecidos nestes Termos de Uso, constituindo este contrato o acordo completo entre as partes. Além disso, declara ter sido informado sobre a política de confidencialidade e proteção de dados pessoais, consentindo livre e expressamente com a coleta, uso, armazenamento e tratamento de suas informações. O LICENCIADO está ciente de que as operações relacionadas a este contrato serão registradas nos bancos de dados da LICENCIANTE, podendo estas informações ser utilizadas como prova.</p>
          </TermItem>
        </Term>
        <Term>
          <TermItem>
            <TermTitle>6. Licença de Uso do Software</TermTitle>
            <p>Estes Termos de Uso concedem ao LICENCIADO uma licença revogável, não exclusiva e intransferível para usar o SOFTWARE, conforme os termos e condições estabelecidos. O LICENCIADO não poderá utilizar o SOFTWARE para finalidades além do processamento de suas informações ou de pessoas jurídicas indicadas no cadastro. O código-fonte do SOFTWARE permanece propriedade exclusiva da LICENCIANTE.</p>
          </TermItem>
        </Term>
        <Term>
          <TermItem>
            <TermTitle>7. Uso de Dados</TermTitle>
            <p>A LICENCIANTE reserva-se o direito de limitar o armazenamento de informações do LICENCIADO a 10 GB e 50 fotos por imóvel. O envio de imóveis para nutrição dos clientes será limitado a 5 mil envios por usuário a cada 30 dias, com renovação automática na data correspondente do mês seguinte.</p>
          </TermItem>
        </Term>
        <Term>
          <TermItem>
            <TermTitle>8. Restrições</TermTitle>
            <p>É estritamente proibido ao LICENCIADO ou a terceiros:</p>
            <ul>
              <li>Copiar, ceder, sublicenciar, vender, alugar, reproduzir, doar, alienar, transferir, total ou parcialmente, o SOFTWARE, seus módulos, partes, manuais ou informações relacionadas.</li>
              <li>Remover ou alterar avisos de reserva de direitos no SOFTWARE e na documentação.</li>
              <li>Praticar engenharia reversa, descompilação ou desmontagem do SOFTWARE.</li>
            </ul>
          </TermItem>
        </Term>
        <Term>
          <TermItem>
            <TermTitle>9. Prazo</TermTitle>
            <p>Estes Termos de Uso entram em vigor na data de aceitação pelo LICENCIADO e permanecerão válidos por prazo indeterminado.</p>
          </TermItem>
        </Term>
        <Term>
          <TermItem>
            <TermTitle>10. Remuneração e Forma de Pagamento</TermTitle>
            <p>O LICENCIADO deve pagar à LICENCIANTE o valor do plano escolhido conforme a periodicidade definida no momento da contratação. Alterações no plano implicarão na atualização dos valores conforme o novo plano selecionado.</p>
            <p>A falta de pagamento nas datas estipuladas resultará na suspensão do acesso ao SOFTWARE até a regularização. A LICENCIANTE poderá contatar o LICENCIADO para resolver pendências financeiras e avisar sobre vencimentos ou renovações próximas. A suspensão por mais de 60 dias poderá levar à exclusão das informações do LICENCIADO no SOFTWARE.</p>
            <p>Os pagamentos via cartão de crédito serão debitados automaticamente ao final de cada período. Para boletos bancários, um novo boleto será enviado 5 dias antes do vencimento, com compensação em 1 a 3 dias úteis. A LICENCIANTE pode ajustar o conteúdo e valores dos planos, criar novos ou excluir existentes a seu critério.</p>
          </TermItem>
        </Term>
        <Term>
          <TermItem>
            <TermTitle>11. Restituição das Informações</TermTitle>
            <p>Em caso de suspensão, a LICENCIANTE manterá as informações do LICENCIADO por 30 dias, permitindo a extração em formato .xls. Após esse período, todas as informações serão permanentemente excluídas.</p>
          </TermItem>
        </Term>
        <Term>
          <TermItem>
            <TermTitle>12. Obrigações do Licenciado</TermTitle>
            <p>O LICENCIADO se compromete a:</p>
            <ul>
              <li>Possuir as autorizações necessárias para inserir dados de clientes e imóveis, sendo responsável por tais informações.</li>
              <li>Responder pelas informações inseridas, cadastramento, permissões, senhas e uso dos usuários, isentando a LICENCIANTE de responsabilidade pelo conteúdo.</li>
              <li>Garantir que não está proibido por lei ou contrato de fornecer informações pessoais à LICENCIANTE.</li>
              <li>Não utilizar o SOFTWARE de forma ilícita ou que viole direitos de terceiros.</li>
              <li>Não enviar arquivos contendo vírus ou programas maliciosos.</li>
              <li>Comunicar imediatamente à LICENCIANTE em caso de suspeita de roubo ou conhecimento não autorizado de login e senha.</li>
            </ul>
          </TermItem>
        </Term>
        <Term>
          <TermItem>
            <TermTitle>13. Obrigações do Licenciante</TermTitle>
            <p>A LICENCIANTE garante que o SOFTWARE funcionará regularmente, respeitando as condições de uso definidas na documentação e mantendo um tempo de atividade mínimo de 95% a cada 30 dias. Em caso de falhas (“bugs”), a LICENCIANTE se compromete a corrigir ou substituir as cópias afetadas.</p>
          </TermItem>
        </Term>
        <Term>
          <TermItem>
            <TermTitle>14. Retomada dos Softwares</TermTitle>
            <p>A LICENCIANTE pode retomar o SOFTWARE caso o LICENCIADO o utilize de forma diversa da estipulada nestes Termos de Uso, sem direito à restituição dos valores pagos.</p>
          </TermItem>
        </Term>
        <Term>
          <TermItem>
            <TermTitle>15. Garantias Limitadas</TermTitle>
            <p>O SOFTWARE é fornecido "no estado em que se encontra" e "conforme a disponibilidade", sem garantia de qualquer espécie. Reconhece-se que o SOFTWARE está em constante evolução e pode apresentar erros. O LICENCIADO compromete-se a reportar falhas para correção, renunciando a qualquer pedido de ressarcimento ou indenização.</p>
          </TermItem>
        </Term>
        <Term>
          <TermItem>
            <TermTitle>16. Backups</TermTitle>
            <ul>
              <li>Backups de dados dos imóveis, clientes, imagens e documentos do USUÁRIO ocorrerão diariamente.</li>
              <li>Backups de e-mails do USUÁRIO ocorrerão semanalmente.</li>
            </ul>
            <p>Recomenda-se que o USUÁRIO mantenha backups de todas as informações para evitar prejuízos decorrentes da perda ou corrupção de dados.</p>
          </TermItem>
        </Term>
        <Term>
          <TermItem>
            <TermTitle>17. Consentimento para Acesso a Informações Confidenciais e Dados Pessoais</TermTitle>
            <p>Ao aceitar utilizar o SOFTWARE, o LICENCIADO consente que a LICENCIANTE colete, use, armazene e trate suas informações, incluindo dados pessoais, necessários para a prestação do serviço.</p>
            <p>O LICENCIADO autoriza a LICENCIANTE a acessar suas informações de conta como mandatária, sendo vedada qualquer transação pela LICENCIANTE. Informações técnicas de navegação poderão ser coletadas para melhorar os serviços oferecidos.</p>
            <p>O LICENCIADO consente que suas informações possam ser transferidas a terceiros em caso de venda, aquisição, fusão ou reorganização societária da LICENCIANTE, comprometendo-se a informar o LICENCIADO nessas situações.</p>
            <p>Cookies serão utilizados para controlar a audiência e navegação no site, permitindo serviços personalizados. A LICENCIANTE garante que tais informações são estatísticas e não pessoais, adotando medidas para evitar o acesso não autorizado por terceiros.</p>
          </TermItem>
        </Term>
        <Term>
          <TermItem>
            <TermTitle>18. Rescisão</TermTitle>
            <p>O LICENCIADO pode rescindir o contrato a qualquer momento, deixando de efetuar novos pagamentos. Para planos com pagamento antecipado, a rescisão antes do término do prazo contratado resultará em reembolso de 80% do saldo restante, sendo os 20% restantes considerados multa operacional.</p>
            <p>Após a rescisão, os dados do LICENCIADO serão excluídos permanentemente 30 dias após a data da rescisão, independentemente de terem sido extraídos ou não.</p>
          </TermItem>
        </Term>
        <Term>
          <TermItem>
            <TermTitle>19. Disposições Legais</TermTitle>
            <p>O LICENCIADO não poderá prestar serviços a terceiros utilizando o SOFTWARE sem autorização prévia da
              LICENCIANTE. Desenvolvimentos que caracterizem cópia parcial ou total do SOFTWARE serão considerados
              propriedade da LICENCIANTE.</p>
            <p>Estes Termos de Uso vinculam as partes e seus sucessores, sendo vedada a transferência dos direitos e
              obrigações pelo LICENCIADO. A LICENCIANTE pode ceder seus direitos a qualquer momento.</p>
            <p>A tolerância quanto ao descumprimento de obrigações não implica em renúncia de direitos. Eventos de caso
              fortuito ou força maior não constituem causa de rescisão contratual.</p>
            <p>Se qualquer disposição destes Termos de Uso for considerada inválida, as demais permanecerão em pleno
              vigor.</p>
            <p>O LICENCIADO concorda que a LICENCIANTE possa divulgar o fechamento do contrato para fins comerciais,
              mencionando seu nome e marca em campanhas. Além disso, o LICENCIADO aceitará receber notificações sobre
              treinamentos, parcerias e campanhas relacionadas ao SOFTWARE.</p>
            <p>A LICENCIANTE está autorizada a coletar e utilizar dados técnicos e operacionais do SOFTWARE para estudos
              e melhorias.</p>
            <p>A LICENCIANTE pode, a seu critério:</p>
            <ul>
              <li>Encerrar, modificar ou suspender o acesso ao SOFTWARE em caso de violação dos termos.</li>
              <li>Excluir informações cadastradas que não estejam em conformidade com os termos.</li>
              <li>Alterar o conteúdo oferecido no site.</li>
            </ul>
            <p>A LICENCIANTE poderá suspender, modificar ou encerrar as atividades do SOFTWARE, mediante comunicação
              prévia, oferecendo alternativas para extração das informações, salvo em casos de força maior.</p>
            <p>A LICENCIANTE poderá definir preços para conteúdos e serviços anteriormente gratuitos, sendo a utilização
              após o aviso considerada como concordância com os novos preços.</p>
          </TermItem>
        </Term>
        <Term>
          <TermItem>
            <TermTitle>20. Modificações nos Termos</TermTitle>
            <p>A LICENCIANTE pode modificar estes termos a qualquer momento. As alterações serão efetivas imediatamente após se tornarem públicas. O USUÁRIO deve revisar periodicamente os Termos de Uso, e o uso contínuo do SOFTWARE implica na aceitação das novas condições.</p>
          </TermItem>
        </Term>
        <Term>
          <TermItem>
            <TermTitle>21. Lei Aplicável</TermTitle>
            <p>Estes Termos de Uso serão regidos pelas leis brasileiras. As partes elegem o foro da Comarca de Tubarão, Santa Catarina, para dirimir quaisquer controvérsias decorrentes deste contrato, com exclusão de qualquer outro, por mais privilegiado que seja. Em caso de indenização, o valor devido não excederá o equivalente aos doze (12) meses anteriores ao fato que gerou a responsabilização.</p>
          </TermItem>
        </Term>
        <Term>
          <TermItem>
            <TermTitle>22. Definições</TermTitle>
            <ul>
              <li><strong>Informações de Conta:</strong> Dados relativos a cartões de crédito, logins, senhas e demais informações necessárias para acessar, coletar, armazenar, usar e tratar informações.</li>
              <li><strong>Informações Pessoais:</strong> Qualquer informação que identifique o LICENCIADO, como nome, endereço, data de nascimento, telefone, e-mail, número de documentos, etc.</li>
              <li><strong>Informações:</strong> Todas as informações do LICENCIADO relacionadas a dados pessoais, financeiros e de conta.</li>
              <li><strong>Licenciado:</strong> Pessoa física ou jurídica, com capacidade para contratar, que acessa o SOFTWARE mediante cadastro, aceitando os termos destes Termos de Uso e utilizando as funcionalidades oferecidas.</li>
              <li><strong>Software:</strong> Programa de propriedade exclusiva da LICENCIANTE, disponibilizado pelo site, que fornece informações financeiras do LICENCIADO diretamente ou coletadas de instituições financeiras de forma automatizada.</li>
            </ul>
          </TermItem>
        </Term>
        <Term>
          <TermItem>
            <TermTitle>Termos de Uso do Processo de Implantação Tecimob</TermTitle>
            <p>Estes Termos de Uso regem o processo de implantação do software Tecimob. Ao iniciar o processo de implantação, você, como Cliente, concorda com os termos descritos a seguir.</p>
          </TermItem>
        </Term>
        <Term>
          <TermItem>
            <TermTitle>1. Objetivo do Processo de Implantação</TermTitle>
            <p>O processo de implantação visa auxiliar na configuração, no entendimento das funcionalidades essenciais e no alinhamento dos pontos críticos da relação entre o cliente e o Tecimob.</p>
          </TermItem>
        </Term>
        <Term>
          <TermItem>
            <TermTitle>2. Escopo do Processo de Implantação</TermTitle>
            <p>Durante o período de implantação, que terá a duração de 5 (cinco) dias úteis, a equipe de implantação do Tecimob se compromete a:</p>
            <ul>
              <li>Auxiliar nas configurações iniciais necessárias para o uso adequado do software.</li>
              <li>
                Alinhar com o Cliente detalhes como:
                <ul>
                  <li>Horários de atendimento e formas de contato para suporte.</li>
                  <li>Esclarecimentos sobre o desenvolvimento contínuo e atualizações do software.</li>
                  <li>Contratação, Renovação, Políticas de cancelamento e as condições aplicáveis.</li>
                  <li>Integrações com ferramentas externas.</li>
                  <li>Limitações na entrega de serviços e recursos.</li>
                </ul>
              </li>
              <li>Demonstrar os principais recursos e funcionalidades do sistema para garantir uma experiência inicial e posterior satisfatória.</li>
              <li>Responder a dúvidas relacionadas ao uso das funcionalidades essenciais.</li>
            </ul>
          </TermItem>
        </Term>
        <Term>
          <TermItem>
            <TermTitle>3. Responsabilidades do Cliente</TermTitle>
            <p>Para garantir o sucesso da implantação, o Cliente deverá:</p>
            <ul>
              <li>Disponibilizar um responsável que possa participar das sessões de alinhamento e configuração.</li>
              <li>Cumprir com os prazos acordados para fornecimento de informações e documentos necessários.</li>
              <li>Participar ativamente da demonstração e dos treinamentos fornecidos, bem como sanar eventuais dúvidas durante o período de implantação.</li>
            </ul>
          </TermItem>
        </Term>
        <Term>
          <TermItem>
            <TermTitle>4. Limitações do Processo de Implantação</TermTitle>
            <p>O processo de implantação limita-se a garantir as configurações iniciais e o alinhamento de uso. Não estão inclusos no escopo:</p>
            <ul>
              <li>Suporte ou atendimento permanente pela equipe de implantação após o prazo de implantação. Os atendimentos posteriores serão fornecidos pelas equipes responsáveis de acordo com cada situação.</li>
              <li>Desenvolvimentos customizados fora das funcionalidades padrão do software.</li>
              <li>Treinamentos extensivos além da demonstração inicial de funcionalidades essenciais.</li>
              <li>Migração de dados de softwares não compatíveis ou que não forneçam arquivos de migração nos padrões solicitados pela equipe.</li>
              <li>Criação de conta ou configurações em plataformas externas, sejam elas integradas nativamente ou não.</li>
              <li>Comunicação por telefone, por outros softwares ou formas não acordadas ou designadas pela equipe.</li>
            </ul>
          </TermItem>
        </Term>
        <Term>
          <TermItem>
            <TermTitle>Cancelamento e Rescisão</TermTitle>
            <p>A Codex Sistemas se reserva o direito de cancelar o processo de implantação nos seguintes casos:</p>
            <ul>
              <li><strong>Desistência do Cliente:</strong> Em caso de desistência por parte do Cliente, o processo poderá ser cancelado, desde que informado ao consultor responsável pela implantação.</li>
              <li><strong>Desrespeito ao Colaborador:</strong> Qualquer conduta desrespeitosa, agressiva ou inadequada em relação ao colaborador durante o processo de implantação poderá resultar na rescisão imediata do processo, a critério exclusivo da empresa.</li>
              <li><strong>Recusa em Seguir o Processo Estabelecido:</strong> O Cliente deverá seguir os passos e orientações estipulados no início do processo de implantação. Caso o Cliente se recuse ou insista em não seguir as etapas determinadas, não compareça ou cancele sua participação em reuniões agendadas, a equipe, por meio de seu consultor(a), poderá cancelar o processo após a notificação da não conformidade e o esgotamento de todas as tentativas de alinhamento.</li>
            </ul>
          </TermItem>
        </Term>
        <Term>
          <TermItem>
            <TermTitle>Disposições Gerais</TermTitle>
            <p>Estes Termos de Uso aplicam-se exclusivamente ao processo de implantação e podem ser atualizados pelo Tecimob a qualquer momento, sem aviso prévio, respeitando os processos já iniciados ou finalizados.</p>
          </TermItem>
        </Term>
        <Term>
          <TermItem>
            <TermTitle>Termos de Uso do Serviço de Personalização de Site</TermTitle>
            <p>Estes Termos de Uso regem o serviço de personalização de site oferecido pelo Tecimob para corretores e imobiliárias. Ao contratar o serviço, você, como Cliente, concorda com os termos descritos a seguir.</p>
          </TermItem>
        </Term>
        <Term>
          <TermItem>
            <TermTitle>1. Escopo do Serviço</TermTitle>
            <p>A personalização realizada será exclusivamente para o site fornecido pelo Tecimob. Não serão realizadas personalizações em redes sociais, cartões de visita ou outros meios de divulgação do corretor.</p>
          </TermItem>
        </Term>
        <Term>
          <TermItem>
            <TermTitle>2. Preço</TermTitle>
            <ul>
              <li>O valor da personalização será de R$100,00 (cem reais).</li>
              <li>O pagamento deve ser confirmado antes do agendamento do serviço.</li>
              <li>O pagamento deverá ser realizado por cartão de crédito ou Pix.</li>
            </ul>
          </TermItem>
        </Term>
        <Term>
          <TermItem>
            <TermTitle>3. Quantidade de Personalizações e Alterações</TermTitle>
            <ul>
              <li>Personalizações: O Cliente terá direito a uma (1) personalização inicial.</li>
              <li>Alterações: Estão incluídas até duas (2) alterações após a conclusão da primeira personalização, para ajustes finais.</li>
            </ul>
          </TermItem>
        </Term>
        <Term>
          <TermItem>
            <TermTitle>4. Locais Personalizáveis</TermTitle>
            <p>A personalização poderá abranger os seguintes elementos do site:</p>
            <ul>
              <li>Imagem de topo.</li>
              <li>Abas laterais (inserção, remoção e definição de cores).</li>
              <li>Imagem de banner inferior (acima do rodapé).</li>
              <li>Cor padrão dos botões.</li>
              <li>Seleção de modelos prontos de estrutura, incluindo topo, pesquisa, super destaques, slides, miniaturas de imóveis da página inicial, layout do imóvel e estilo de rodapé.</li>
            </ul>
          </TermItem>
        </Term>
        <Term>
          <TermItem>
            <TermTitle>5. Imagens Utilizadas</TermTitle>
            <p>As imagens utilizadas para personalização serão selecionadas de bancos de imagens como Freepik e Pixabay. Caso o Cliente deseje uma imagem específica que não esteja disponível nesses bancos, deverá fornecer a imagem desejada.</p>
          </TermItem>
        </Term>
        <Term>
          <TermItem>
            <TermTitle>6. Exclusões do Serviço</TermTitle>
            <p>Não estão incluídas na personalização:</p>
            <ul>
              <li>Criação de logotipos.</li>
              <li>Criação ou alteração de menus e submenus do site.</li>
              <li>Criação ou alteração de cartões de visita.</li>
              <li>Criação, personalização ou alteração de slides de imóveis, superdestaques e vídeos da página
                inicial.
              </li>
              <li>Criação de imagens ou textos de depoimentos.</li>
              <li>Quaisquer personalizações não presentes na cláusula 4.</li>
            </ul>

          </TermItem>
        </Term>
        <Term>
          <TermItem>
            <TermTitle>7. Prazo de Conclusão</TermTitle>
            <ul>
              <li>A primeira personalização será concluída em um prazo de 1 (um) a 5 (cinco) dias úteis.</li>
              <li>As alterações solicitadas pelo Cliente serão concluídas em até 3 (três) dias úteis após cada solicitação.</li>
            </ul>
          </TermItem>
        </Term>
        <Term>
          <TermItem>
            <TermTitle>8. Política de Cancelamento e Reembolso</TermTitle>
            <ul>
              <li><strong>Cancelamento:</strong> O cancelamento do serviço de personalização poderá ser solicitado até o aviso de início da personalização do site, enviada por e-mail automático.</li>
              <li><strong>Reembolso:</strong> Em conformidade com o Direito de Arrependimento, o Cliente poderá solicitar o reembolso integral em até 7 (sete) dias a partir da contratação do serviço. Após esse prazo, não será possível receber o reembolso; no entanto, o valor poderá ser convertido em dias proporcionais ao contrato da plataforma.</li>
            </ul>
          </TermItem>
        </Term>
        <Term>
          <TermItem>
            <TermTitle>9. Envio de Materiais Utilizados</TermTitle>
            <p>Ao final da personalização e das alterações, os materiais utilizados serão enviados ao Cliente diretamente no e-mail preenchido no formulário, em formato a ser definido.</p>
          </TermItem>
        </Term>
        <Term>
          <TermItem>
            <TermTitle>Termos de Uso do Serviço de Treinamento</TermTitle>
            <p>Estes Termos de Uso regem o serviço de treinamento oferecido pelo Tecimob para corretores e imobiliárias. Ao contratar o serviço, você, como Cliente, concorda com os termos descritos a seguir.</p>
          </TermItem>
        </Term>
        <Term>
          <TermItem>
            <TermTitle>1. Preço</TermTitle>
            <ul>
              <li>O valor do treinamento será de R$100,00 (Cem reais).</li>
              <li>O pagamento deve ser efetuado antes da liberação do agendamento, conforme as condições estabelecidas abaixo.</li>
            </ul>
          </TermItem>
        </Term>
        <Term>
          <TermItem>
            <TermTitle>2. Liberação do Agendamento</TermTitle>
            <ul>
              <li>O agendamento do treinamento será liberado exclusivamente após a confirmação do pagamento. Não haverá exceções a esta cláusula.</li>
            </ul>
          </TermItem>
        </Term>
        <Term>
          <TermItem>
            <TermTitle>3. Formas de Pagamento</TermTitle>
            <ul>
              <li>O pagamento poderá ser efetuado via cartão de crédito ou Pix.</li>
            </ul>
          </TermItem>
        </Term>
        <Term>
          <TermItem>
            <TermTitle>4. Realização do Treinamento</TermTitle>
            <ul>
              <li>O treinamento será realizado através da plataforma Google Meet, com duração de 1 (uma) hora. A data e o horário serão previamente agendados entre o Cliente e o Tecimob.</li>
              <li>O treinamento se limita a recursos e funcionalidades do Tecimob, não sendo abordados assuntos referentes à Marketing, Integrações Externas ou Serviços Correlacionados.</li>
              <li>Durante o treinamento, será utilizada uma conta de teste e não será realizada nenhuma configuração na conta do cliente.</li>
              <li>O início e fim do treinamento respeitarão o horário agendado, não sendo estendidos por atrasos ocorridos por parte do cliente.</li>
            </ul>
          </TermItem>
        </Term>
        <Term>
          <TermItem>
            <TermTitle>5. Recomendações para Participação</TermTitle>
            <p>Para uma melhor experiência, recomenda-se que o Cliente:</p>
            <ul>
              <li>Utilize um computador, em vez de dispositivos móveis.</li>
              <li>Faça uso de fones de ouvido para maior clareza no áudio.</li>
              <li>Participe do treinamento em um local silencioso, sem muitos ruídos e interrupções.</li>
              <li>Acesse uma rede de internet estável ou 4G, de forma que a conexão suporte a transmissão por vídeo-chamada sem travamentos ou interrupções.</li>
            </ul>
          </TermItem>
        </Term>
        <Term>
          <TermItem>
            <TermTitle>6. Alteração de Preço</TermTitle>
            <p>O Tecimob reserva-se o direito de alterar o valor do treinamento sem aviso prévio. Contudo, os serviços já contratados permanecerão com o valor acordado na data da contratação.</p>
          </TermItem>
        </Term>
        <Term>
          <TermItem>
            <TermTitle>7. Política de Reagendamento</TermTitle>
            <ul>
              <li>O Cliente poderá solicitar até 2 (dois) reagendamentos para o treinamento, desde que a solicitação seja feita com no mínimo 1 (uma) hora de antecedência. Caso o Cliente não solicite o reagendamento dentro deste prazo, o treinamento será considerado realizado, e o Cliente será informado sobre a ausência.</li>
              <li>Caso a equipe tenha algum problema técnico que impeça a permanência no treinamento, como oscilações na rede ou falha nos dispositivos, será liberado automaticamente um novo horário completo ao cliente.</li>
              <li>Caso a equipe tenha algum problema técnico que impeça a permanência no treinamento, como oscilações na rede ou falha nos dispositivos, o horário restante do treinamento será liberado para posterior reagendamento.</li>
            </ul>
          </TermItem>
        </Term>
        <Term>
          <TermItem>
            <TermTitle>8. Política de Cancelamento e Reembolso</TermTitle>
            <ul>
              <li><strong>Cancelamento:</strong> O cancelamento do treinamento poderá ser solicitado até 1 (uma) hora antes do evento, sem perda do serviço contratado.</li>
              <li><strong>Reembolso:</strong> Em conformidade com o Direito de Arrependimento, o Cliente poderá solicitar o reembolso integral em até 7 (sete) dias a partir da contratação do serviço. Após esse prazo, não será possível receber o reembolso; no entanto, o valor poderá ser convertido em dias proporcionais ao contrato da plataforma.</li>
              <li>Após a realização do treinamento, não haverá possibilidade de reembolso ou cancelamento, devido ao serviço ter sido usufruído.</li>
            </ul>
          </TermItem>
        </Term>
        <Term>
          <TermItem>
            <TermTitle>9. Gravação do Treinamento</TermTitle>
            <ul>
              <li>O treinamento ao vivo não será gravado. Contudo, o Tecimob poderá disponibilizar ao Cliente links de materiais previamente gravados para consulta a qualquer momento.</li>
            </ul>
          </TermItem>
        </Term>
        <Term>
          <TermItem>
            <TermTitle>Termos de Uso do Módulo Financeiro</TermTitle>
            <p>O Módulo Financeiro é uma ferramenta voltada para o gerenciamento financeiro, que permite:</p>
            <ul>
              <li>Controle de receitas e despesas;</li>
              <li>Organização de contas a pagar e a receber;</li>
              <li>Acompanhamento de saldos;</li>
              <li>Categorias customizáveis para melhor planejamento financeiro;</li>
              <li>Relatórios gerenciais e gráficos de performance;</li>
            </ul>
          </TermItem>
        </Term>
        <Term>
          <TermItem>
            <TermTitle>2. Condições de Uso</TermTitle>
            <p><strong>2.1. Elegibilidade</strong></p>
            <p>O uso do Módulo Financeiro está disponível apenas para clientes ativos do sistema principal do Tecimob, não sendo permitida sua contratação à parte.</p>
            <p><strong>2.2. Licença de Uso</strong></p>
            <p>O Tecimob concede uma licença de uso não exclusiva e intransferível do módulo, limitada ao período de sua assinatura.</p>
          </TermItem>
        </Term>
        <Term>
          <TermItem>
            <TermTitle>3. Proibições</TermTitle>
            <p>É proibido utilizar o módulo para atividades ilícitas ou violar direitos de terceiros. Tentativas de engenharia reversa, modificação ou exploração do código do módulo são estritamente proibidas.</p>
          </TermItem>
        </Term>
        <Term>
          <TermItem>
            <TermTitle>4. Limitações de Uso</TermTitle>
            <p>O módulo é fornecido "como está". Não garantimos suporte para integrações com sistemas de terceiros não especificados, personalizações ou edições realizadas.</p>
          </TermItem>
        </Term>
        <Term>
          <TermItem>
            <TermTitle>5. Garantias</TermTitle>
            <p><strong>5.1. Atualização e Funcionamento</strong></p>
            <p>O Tecimob se compromete a manter o módulo atualizado e funcionando dentro do escopo de seus recursos.</p>
            <p><strong>5.2. Limitações de Responsabilidade</strong></p>
            <p>Não nos responsabilizamos por:</p>
            <ul>
              <li>Dados incorretos inseridos pelo usuário;</li>
              <li>Perdas financeiras decorrentes de falhas no uso do módulo;</li>
              <li>Integrações com sistemas de terceiros que não sejam oficialmente suportados.</li>
            </ul>
            <p><strong>5.3. Recomendações</strong></p>
            <p>É estritamente recomendado que sejam mantidas cópias dos dados inseridos em local seguro e de fácil
              acesso, para situações de indisponibilidade do serviço.</p>
          </TermItem>
        </Term>

        <Term>
          <TermItem>
            <TermTitle>Data da última revisão do documento</TermTitle>
            <p>Tubarão, 09 de Dezembro de 2024.</p>
          </TermItem>
        </Term>
      </Content>
    </LayoutTerms>
  );
}
